import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WebriQForm from "../components/form/form"
import Bgcontact from "../images/contactbg.png"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" description="Contact Page" />
    <div
      className="page-headline"
      style={{ backgroundImage: `url(${Bgcontact})` }}
    >
      <div className="container">
        <div className="section-heading text-center">
          <h1 className="font-weight-bold text-uppercase flair">
            <strong>CONTACT US</strong>
          </h1>
        </div>
      </div>
    </div>
    <div id="contact" className="contact-section padding-200">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="contact-text text-center">
              <p>
                Please contact us today if you require a combination sewer
                cleaner, vacuum loader, hydro-excavator, easement machine, truck
                or trailer mounted jetter, flood truck, pipeline inspection
                camera, root cutter, pipeline management software, large
                capacity pump or any specialized nozzles or jets. Dawson
                represents industry-leading manufacturers of proven
                technologies, including RamVac, ITpipes, Insight Vision, Sewer
                Equipment Company of America, Vivax-Metrotech, Mongoose Jetters,
                Warthog Sewer Nozzles, Helix Labs and USB Sewer Equipment
                Corporation.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 p-3 text-center">
            <h2>COLORADO</h2>
            <p className="mb-2">
              <i className="fa fa-map-marker fa-lg mr-2"></i>
              <strong>434 East 56th Avenue Denver, CO 80216</strong>
            </p>
            <p className="mb-2">
              <a href="tel:(801) 207.9728">
                <i className="fa fa-phone fa-lg mr-2"></i>(801) 207.9728
              </a>
            </p>
            <p>
              <a href="tel:(303) 632.8236">
                <i className="fa fa-phone fa-lg mr-2"></i>(303) 632.8236
              </a>
            </p>
            <p>
              <a href="tel:(303) 289.8565">
                <i className="fa fa-fax fa-lg mr-2"></i>(303) 289.8565
              </a>
            </p>
            <iframe
              title="434 East 56th Avenue Denver, CO 80216"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.398561092117!2d-104.98337888525246!3d39.79805077944181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c79b5c07bd1a1%3A0x13d7072eb8d77e81!2s434%20E%2056th%20Ave%2C%20Denver%2C%20CO%2080216%2C%20USA!5e0!3m2!1sen!2sph!4v1590041907556!5m2!1sen!2sph"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              className="shadow"
            ></iframe>
          </div>
          <div className="col-sm-12 col-md-6 p-3 text-center">
            <h2>UTAH</h2>
            <p className="mb-2">
              <i className="fa fa-map-marker fa-lg mr-2"></i>
              <strong>
                47 South Orange Street Suite A5-6 Salt Lake City, UT 84116
              </strong>
            </p>
            <p className="mb-2">
              <a href="tel:(801) 207.9728">
                <i className="fa fa-phone fa-lg mr-2"></i>(801) 207.9728
              </a>
            </p>
            <p>
              <a href="tel:(303) 632.8236">
                <i className="fa fa-phone fa-lg mr-2"></i>(303) 632.8236
              </a>
            </p>
            <p>
              <a href="tel:(303) 289.8565">
                <i className="fa fa-fax fa-lg mr-2"></i>(303) 289.8565
              </a>
            </p>
            <iframe
              title="47 South Orange Street Suite A5-6 Salt Lake City, UT 84116"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.711354709662!2d-111.94534468521402!3d40.7683728793258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752f47b0985cdbf%3A0xed24bceb41eee3ab!2s47%20Orange%20St%20Suite%20A5-6%2C%20Salt%20Lake%20City%2C%20UT%2084116%2C%20USA!5e0!3m2!1sen!2sph!4v1590041953807!5m2!1sen!2sph"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              className="shadow"
            ></iframe>
          </div>

          <div className="col-md-8 mx-auto my-5 contact-clean shadow">
            <WebriQForm
              method="post"
              for_name="Contact Us Form"
              form_id="720c21c2-8c32-4e0d-b2f5-92bd4e1a2a3d"
              className="contact-us"
              data-thankyou-url="/thank-you"
            >
              <h2 className="text-center contact-heading mt-0">Get in Touch</h2>
              <p className="text-center">
                We're here to help create your dream a reality!
              </p>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="Name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="Email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="Company"
                      placeholder="Company"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      className="form-control mb-2"
                      name="Message"
                      placeholder="Message"
                      rows={14}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group w-100 px-1 mt-2">
                  <button
                    className="btn-global hvr-bounce-to-right hvr-icon-buzz text-center w-100"
                    type="submit"
                  >
                    send MESSAGE
                  </button>
                </div>
              </div>
            </WebriQForm>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
